<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <div class="my-card-top">
                <div class="left">
                  <div class="role">
                    <img
                      class="pic"
                      :src="item.HeadUrl"
                      alt=""
                      @error="errorImg"
                    />
                  </div>
                  <div class="info">
                    <div class="info-head">
                      {{ item.IdentityName }}
                    </div>
                  </div>
                </div>
                <div class="right">
                  <template v-if="item.Reply == '未回复'">
                    <div class="noreply">未回复</div>
                  </template>
                  <template v-else>
                    <div class="reply">{{ item.Reply }}</div>
                  </template>
                </div>
              </div>
              <div class="content-box">
                <div>
                  {{ item.Content }}
                </div>
                <div class="box-date">
                  <span>{{ item.CreateDate }}</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/ic_role0.png";
import { Button, Icon, List, PullRefresh, Dialog, Field } from "vant";
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
  },
  data() {
    return {
      defaultImg: defaultImg,
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      finished: false, // false
      refreshing: false,
      dialogVisible: false,
      id: "",
      CancelDesc: "",
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
    };
  },
  mounted() {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++;
      this.getList();
    },
    getList() {
      const that = this;
      that.$axios
        .get("/api/ParentMailbox/TeacherGetMailList", {
          page: this.page, // 页码
          limit: this.limit, // 每页条数
        })
        .then((res) => {
          if (res.code === 200) {
            that.refreshing = false;
            that.loading = false;
            if (res.data) {
              that.dataList = that.dataList.concat(res.data);
            }
            if (that.dataList.length >= res.count) {
              that.finished = true;
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 下拉刷新
    onRefresh() {
      this.page = 0;
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = [];
      this.getDataList();
    },
    handleClick(item) {
      this.$router.push({
        path: "MailboxView",
        query: {
          id: item.ID,
        },
      });
    },
    errorImg() {
      const img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
